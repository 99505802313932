import log from './logger';
import getCachedSegments from './getCachedSegments';

const segmentSyncs = dmps => {
  // TODO: check if we could enrich the dmp object instead
  // and get the segment directly instead of storage
  // but with he callqueue, not sure if they are retrieved
  // before the cX.sync
  const _appNexusS2s = (id, seg) => {
    if (seg.length > 0) {
      const el = document.createElement('img');
      const prefix = 'cxsegment_';
      const keyvalues = [];
      keyvalues.push(`add_Code=${prefix}${seg.join(`,${prefix}`)}`);
      keyvalues.push(`member=${id}`);

      el.setAttribute('src', `//ib.adnxs.com/seg?${keyvalues.join('&')}`);
      el.setAttribute('width', '1');
      el.setAttribute('height', '1');
      el.style.width = '1px';
      el.style.height = '1px';
      if (document.body) document.body.appendChild(el);
      // const url = `//ib.adnxs.com/seg?${keyvalues.join('&')}`;
      // fetch(url)
      //   .then(log(response))
    }
  };

  const _facebook = (id, seg) => {
    let syncAttempts = 1;

    try {
      // fbq ignores long arrays
      const chunk = 80;
      let i = 0;

      for (i; i < seg.length; i += chunk) {
        window.fbq('trackSingleCustom', id, 'CxSegments', {
          segmentIds: seg.slice(i, i + chunk),
        });
      }
    } catch (err) {
      if (syncAttempts <= 4) {
        setTimeout(() => {
          _facebook(id, seg);
        }, 200);
        log(`Waiting on Facebook pixel, try: ${syncAttempts} of 4`);
        syncAttempts += 1;
      } else {
        // continue without fbq sync
      }
    }
  };
  dmps.forEach(dmp => {
    dmp.segments.forEach(segment => {
      if (segment.syncs) {
        segment.syncs.forEach(sync => {
          switch (sync.partner) {
            case 'appnexus':
              _appNexusS2s(
                sync.customerId,
                getCachedSegments(segment.name, segment.type)
              );
              break;
            case 'facebook':
              _facebook(
                sync.customerId,
                getCachedSegments(segment.name, segment.type)
              );
              break;
            default:
          }
          log(
            `add segmentsync for ${sync.partner} (${sync.customerId}) with ${
              segment.name
            } to queue`
          );
        });
      }
    });
  });
};

export default segmentSyncs;
